import { brushDataTypes } from "./constants";
import {
  getDistanceUnitText,
  getTemperatureUnitText,
} from "./measurementUnits";

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isBetweenValues(
  value: number | string,
  min: number,
  max: number
) {
  let parsedVal = parseInt(value.toString());
  var x = parsedVal >= min && parsedVal <= max;
  return x;
}

export const getTypeSuffix = (type: string, currentUser: any) => {
  switch (type) {
    case brushDataTypes.temp.value:
    case brushDataTypes.ambientTemp.value:
      return getTemperatureUnitText(currentUser?.userSettings.temperatureUnit!);
    case brushDataTypes.wear.value:
    case brushDataTypes.ambientHumidity.value:
      return "%";
    case brushDataTypes.currentFreq.value:
      return "Hz";
    case brushDataTypes.currentAmpl.value:
      return "A";
    case brushDataTypes.wearMm.value:
      return getDistanceUnitText(currentUser?.userSettings.distanceUnit!);
    default:
      return "";
  }
};
