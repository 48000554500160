function round(value: number, decimals: number) {
  // return Number(Math.round(value).toFixed(decimals));
  //return Number(value.toFixed(0));
  return Number(value);
  // return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

const formatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
});

function fahrenheitToCelsius(value: number) {
  return (value - 32) / 1.8;
  //return round((5 / 9) * (value - 32), 2);
}

function celsiusToFahrenheit(value: number) {
  var fahrenheit = value * 1.8 + 32;

  var formatedValue = formatter.format(fahrenheit) as unknown as number;
  return formatedValue;
  // return round(fahrenheit, 2);
  //return (value * 1.8) + 32;
  //return round(1.8 * value + 32, 2);
}

function getTemperatureUnitText(temperatureUnit: string) {
  if (temperatureUnit === "fahrenheit") {
    return "°F";
  } else if (temperatureUnit === "celsius") {
    return "°C";
  } else {
    return "°C";
  }
}

function getDistanceUnitText(distanceUnit: string) {
  if (distanceUnit === "metric") {
    return "mm";
  } else if (distanceUnit === "uscs") {
    return "in";
  } else {
    return "mm";
  }
}

function convertTemperature(temperatureUnit: string, value: number) {
  if (temperatureUnit === "fahrenheit") {
    return celsiusToFahrenheit(value);
    // return round(celsiusToFahrenheit(value), 2);
  } else if (temperatureUnit === "toCelsius") {
    return round(fahrenheitToCelsius(value), 2);
  } else {
    return round(value, 2);
  }
}

function convertDistance(distanceUnit: string, value: number) {
  if (distanceUnit === "metric") {
    return round(value, 4); // stored in millimeters
  } else if (distanceUnit === "uscs") {
    return round(value / 25.4, 4);
  } else {
    return round(value, 4);
  }
}

export {
  round,
  fahrenheitToCelsius,
  celsiusToFahrenheit,
  getTemperatureUnitText,
  getDistanceUnitText,
  convertTemperature,
  convertDistance,
};
