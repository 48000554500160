import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { HashRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
      <Auth0Provider
        useRefreshTokens={true}
        domain="carbex-ibrush-cloud.eu.auth0.com"
        clientId="DyAMwI3V0ApHufPtRZON007IUWvycSie"
       //redirectUri={"http://localhost:3000"}
        redirectUri={window.location.origin}
       // audience="https://dev-1vqbztsc.us.auth0.com/api/v2/"
        audience="https://carbex-ibrush-cloud.com/api"
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
