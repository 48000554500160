import { useState, useEffect, lazy, Suspense } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import * as apiClient from "./services/apiClient";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import { IbrushContext } from "./common/constants";
import { IUser } from "./models/IUser";

const Sites = lazy(() => import("./pages/Sites"));
const Devices = lazy(() => import("./pages/Devices"));
const Device = lazy(() => import("./pages/Device"));
const Administration = lazy(() => import("./pages/Admin/Administration"));
const Profile = lazy(() => import("./pages/Profile"));

function App() {
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [userNickname, setUserNickname] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  const handleLogout = () => {
    setUserRoles([]);
    logout();
  };

  const handleAuthChange = async () => {
    if (typeof user !== "undefined") {
      if (
        typeof user.nickname !== "undefined" &&
        typeof user.email !== "undefined"
      ) {
        setUserNickname(user.nickname);
        setUserEmail(user.email);
        const token = await getAccessTokenSilently();
        const isAdmin = await apiClient.isAdmin(token);
        if (isAdmin) {
          setUserRoles(["admin"]);
        } else {
          setUserRoles(["user"]);
        }
      }
    }
  };

  const getUser = async () => {
    if (userEmail === "") {
      return;
    }

    const token = await getAccessTokenSilently();
    const user = await apiClient.getUserByEmail(token, userEmail);
    if (user !== "undefined") {
      setCurrentUser(user);
    }
  };

  useEffect(() => {
    handleAuthChange();
  }, [user]);

  useEffect(() => {
    getUser();
  }, [userEmail]);

  return (
    <IbrushContext.Provider value={{ currentUser }}>
      <Suspense fallback={<h1 className="loader">Loading</h1>}>
        <div className="App">
          <Navbar
            userRoles={userRoles}
            userNickname={userNickname}
            onLogout={handleLogout}
          />
          <Routes>
            <Route path="profile" element={<Profile />} />
            <Route
              path="device/:deviceId"
              element={<Device userEmail={userEmail} />}
            />
            <Route
              path="devices/:siteId"
              element={<Devices userEmail={userEmail} />}
            />
            <Route path="sites" element={<Sites userEmail={userEmail} />} />
            <Route path="administration" element={<Administration />} />
            <Route path="*" element={<Sites userEmail={userEmail} />} />
          </Routes>
        </div>
      </Suspense>
    </IbrushContext.Provider>
  );
}

export default App;
